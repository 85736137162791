import React, { useEffect, useState } from "react";
import axiosInstance from "../api/axiosInstance";
import SEO from "../components/SEO";

const AboutUs = () => {
    return (
        <div className="bg-gray-50 min-h-screen py-16">
            <SEO
                title="About Us | AAEC Global"
                description="We are a leading educational consultancy firm, dedicated to guiding students towards academic success. With years of experience in the education sector, we provide personalized support, helping students find the best institutions and programs globally."
                name="About Us"
                type="website"
                path="/about-us"
            />
            <div className="container mx-auto px-6 lg:px-12 text-center">
                <h1 className="text-5xl font-extrabold text-gray-900 mb-6">About Us</h1>

                <div className="flex flex-col lg:flex-row items-center lg:items-start space-y-10 lg:space-y-0 lg:space-x-16">
                    <div className="lg:w-1/2">
                        <img src="/logo.png" alt="Educational Consultancy" className="rounded-xl shadow-xl w-full" loading="lazy" />
                    </div>
                    <div className="lg:w-1/2 text-left">
                        <Section title="Who We Are">
                            Abroad Application Educational Consultancy also known as AAEC GLOBAL is a leading educational consultancy firm dedicated to guiding students towards academic success. With years of experience, we provide personalized support, helping students find the best institutions and programs globally.
                        </Section>
                        <Section title="Our Mission">
                            Our mission is to empower students by providing expert advice and comprehensive solutions for their academic journey. We believe every student deserves access to the best education opportunities and the resources to thrive.
                        </Section>
                    </div>
                </div>
                <CardWrapper />
            </div>
        </div>
    );
};

const Section = ({ title, children }) => (
    <div className="mb-8">
        <h2 className="text-3xl font-semibold text-gray-800 mb-3">{title}</h2>
        <p className="text-gray-600 text-lg leading-relaxed">{children}</p>
    </div>
);

const CardWrapper = () => {
    const [members, setMembers] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [branchData, setBranchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const pageSize = 6; // Number of items per page
    const totalPages = Math.ceil(members.length / pageSize);
    const paginatedData = members.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const fetchTeamMembers = async () => {
        setLoading(true);
        try {
            const [teamResponse, branchResponse] = await Promise.all([
                axiosInstance.get("/api/users/team/team-members/"),
                axiosInstance.get("/api/users/branches/")
            ]);

            const branchData = branchResponse.data.data;
            setBranchData(branchData);

            const newTeamData = teamResponse.data.results.map((team) => {
                const branch = branchData.find((branch) => branch.id === team.branch);
                return {
                    ...team,
                    branchName: branch ? branch.branch_name : "Unknown Branch",
                };
            });

            setMembers(newTeamData);
        } catch (error) {
            setError("Failed to fetch team members.");
            console.error("Error fetching team members:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTeamMembers();
    }, []);

    return (
        <div className="pt-16">
            <h3 className="text-4xl font-bold text-gray-800 mb-8">Meet Our Team</h3>

            {loading ? (
                <p className="text-gray-500 text-xl">Loading...</p>
            ) : error ? (
                <p className="text-red-500 text-xl">{error}</p>
            ) : (
                <>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {paginatedData.map((member) => (
                            <Card key={member.id} data={member} />
                        ))}
                    </div>

                    {/* Pagination Controls */}
                    <div className="flex justify-center items-center mt-8 space-x-2">
                        <button
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className={`px-4 py-2 rounded-lg text-white font-semibold ${currentPage === 1 ? "bg-gray-400 cursor-not-allowed" : "bg-[#01132b] hover:bg-blue-700"
                                }`}
                        >
                            Previous
                        </button>

                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => setCurrentPage(index + 1)}
                                className={`px-3 py-2 rounded-lg font-semibold ${currentPage === index + 1
                                        ? "bg-[#01132b] text-white"
                                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                    }`}
                            >
                                {index + 1}
                            </button>
                        ))}

                        <button
                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                            className={`px-4 py-2 rounded-lg font-semibold text-white ${currentPage === totalPages ? "bg-gray-400 cursor-not-allowed" : "bg-[#01132b] hover:bg-blue-700"
                                }`}
                        >
                            Next
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

const Card = ({ data }) => {
    return (
        <div className="bg-[#01132b] shadow-xl rounded-2xl overflow-hidden border border-gray-300 transform hover:scale-105 transition duration-300 ease-in-out">
            <div className="relative w-full h-48 flex justify-center items-center">
                <img
                    src={data.photo}
                    alt={`Profile of ${data.name}`}
                    className="w-32 h-32 rounded-full border-4 border-white shadow-md object-cover"
                    loading="lazy"
                />
            </div>
            <div className="p-6 text-center bg-gray-200">
                <h2 className="text-2xl font-extrabold text-gray-800">{data.name}</h2>
                <h3 className="text-lg text-blue-600 font-medium">{data.position}</h3>
                <div className="mt-3 text-gray-600 text-sm space-y-1">
                    <p className="font-semibold">{data.branchName}</p>
                    <p>{data.address}</p>
                    <p>
                        <a href={`tel:${data.phone_number}`} className="text-blue-500 font-medium hover:underline">
                            {data.phone_number}
                        </a>
                    </p>
                    <p>
                        <a href={`mailto:${data.email}`} className="text-blue-500 font-medium hover:underline">
                            {data.email}
                        </a>
                    </p>

                </div>
            </div>
        </div>
    );
};

export default AboutUs;
