import React, { useEffect, useState } from 'react';
import axiosInstance from '../../api/axiosInstance';

const ITEMS_PER_PAGE = 6;

const BranchGridHome = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [branches, setBranches] = useState([]);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentBranches = branches.slice(startIndex, endIndex);

  // Determine the total number of pages
  const totalPages = Math.ceil(branches.length / ITEMS_PER_PAGE);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    axiosInstance.get("/api/users/branches")
      .then(response => {
        setBranches(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <div className="p-6 mx-auto max-w-screen-lg">
      <h1 className="text-2xl sm:text-3xl font-bold text-center mb-6">Branch List</h1>

      {branches.length === 0 ? (
        <div className="text-center text-gray-500 text-lg">No branch available</div>
      ) : (
        <div className="flex flex-wrap justify-center items-stretch gap-6 md:gap-10 w-full">
          {currentBranches.map((branch) => (
            <div
              key={branch.id}
              className="flex flex-col items-center justify-between max-w-sm bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-gray-50 duration-300 ease-in-out"
            >
              <h2 className="text-xl font-semibold mb-2 text-center">{branch.branch_name}</h2>
              <p className="text-gray-700 mb-2 text-center">{branch.city}</p>
              <p className="text-gray-700 mb-2 text-center">Manager: {branch.manager_name}</p>
              
              {/* Clickable Phone Number */}
              <p className="text-gray-700 mb-2 text-center">
                Phone: 
                <a href={`tel:${branch.phone_number}`} className="text-blue-600 hover:underline ml-1">
                  {branch.phone_number}
                </a>
              </p>

              {/* Clickable Email */}
              <p className="text-gray-700 mb-2 text-center">
                Email: 
                <a href={`mailto:${branch.email}`} className="text-blue-600 hover:underline ml-1">
                  {branch.email}
                </a>
              </p>

              <p className="text-gray-700 mb-4 text-center">{branch.desc}</p>
              
              <a
                href={branch.map_link}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#eb4934] px-4 py-2 text-white font-bold rounded hover:bg-[#d43b2e] no-underline"
              >
                View on Map
              </a>
            </div>
          ))}
        </div>
      )}

      {branches.length > 0 && (
        <div className="flex flex-wrap justify-center mt-8 gap-2">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-4 py-2 mx-2 rounded ${currentPage === index + 1 ? 'bg-[#eb4934] text-white' : 'bg-gray-200 text-gray-700'}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default BranchGridHome;
