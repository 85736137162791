import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net';
import 'datatables.net-responsive-dt';
import 'datatables.net-select-dt';
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';

// Function to format the date in YYYY/MM/DD, HH:mm:ss format
function formatDate(dateString) {
  const dateObj = new Date(dateString);
  if (isNaN(dateObj)) {
    return 'Invalid Date'; // Return a fallback if date is invalid
  }
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  return `${year}/${month}/${day}, ${hours}:${minutes}:${seconds}`;
}

function Datatable({ leads }) {
  const tableRef = useRef();

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      data: leads,
      searching: false,
      columns: [
        { 
          title: 'Date', 
          data: 'created_at',
          render: (data) => formatDate(data) // Apply formatting here
        },
        { title: 'Name', data: 'name' },
        { title: 'Email', data: 'email' },
        { title: 'Message', data: 'message' },
      ],
      dom: 'Bfrtip', // Define the elements' display order
      buttons: [
        'copyHtml5',
        'csvHtml5',
        'print',
      ],
    });

    return () => {
      table.destroy();
    };
  }, [leads]);

  return <table ref={tableRef} className="display" style={{ width: '100%' }} />;
}

export default Datatable;
