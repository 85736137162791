import React from "react";
import ControlledCarousel from "../components/maincarousel/carousel.jsx";
import TestPrep from "../components/testprep/testPrep.jsx";
import Destination from "../components/destination.jsx";
import BranchGridHome from "../components/branch/branchGrid.jsx";
import ArticlesGridHome from "../components/home/articlesGridHome.jsx";
import EducationalConsultancy from "../components/home/homeAboutUs.jsx";
import FAQ from "../components/home/homeFAQ.jsx";
import { Link } from "react-router-dom";
import CounterSection from "../components/counter/counter.jsx";
import SEO from "../components/SEO.jsx";

function Home() {
  return (
    <div>
      <SEO
        title={"Home | AAEC Global"}
        description={"AAEC Global is one of the leading providers of educational consultancy services in Nepal for aspirants who are willing to study abroad. Our educational consultants in Nepal have expertise in offering counseling to aspirants. USA, UK, Australia, Canada, New Zealand, Europe are some of the most preferable destinations for pursuing higher studies. Students from all across the world have a desire to study in developed countries as they have better academic structure, excellent career growth, and better living standards."}
        name={"Home"}
        type={"website"}
        path={"/"}
      />
      <ControlledCarousel />
      <div className="px-4 py-6 max-w-7xl mx-auto ">
        <h2 className="text-2xl font-bold mb-6">
          Leading Study Abroad Consultants in Kathmandu, Nepal
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">
              We help you to prepare all your documents
            </h3>
            <ul className="list-decimal list-inside">
              <li>
                <Link to="/tools/cv-builder" className="text-blue-500 hover:underline">
                  CV Builder
                </Link>
              </li>
              <li>
                <Link to="/tools/documents/relationship-certificate" className="text-blue-500 hover:underline">
                  Relationship Certificate
                </Link>
              </li>
              <li>
                <Link to="/tools/documents/birth-certificate" className="text-blue-500 hover:underline">
                  Birth Certificate
                </Link>
              </li>
              <li>
                <Link to="/tools/documents/address-verification" className="text-blue-500 hover:underline">
                  Address Verification
                </Link>
              </li>
              <li>
                <Link to="/tools/documents/name-verification" className="text-blue-500 hover:underline">
                  Name Verification
                </Link>
              </li>
              <li>
                <Link to="/tools/documents/tax-clearance" className="text-blue-500 hover:underline">
                  Tax Clearance
                </Link>
              </li>
              <li>
                <Link to="/tools/documents/income-source-certificate" className="text-blue-500 hover:underline">
                  Income Source Certificate
                </Link>
              </li>
              <li>
                <Link to="/tools/documents/third-party-income-verification" className="text-blue-500 hover:underline">
                  Third Party Income Verification
                </Link>
              </li>
            </ul>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <img src="card1.png" alt="Career Counselling" className="mx-auto mb-4" />
            <h4 className="text-xl font-bold mb-2">Career Counselling</h4>
            <p className="mb-4">
              All our counselling sessions are free and our experienced
              counsellors help you with the university and course selection.
            </p>
            <Link to="/careercounselling" className="bg-[#eb4934] px-4 py-2 text-white font-bold rounded inline-block hover:bg-[#d43f2b]">
              Know More
            </Link>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <img src="card2.png" alt="Visa Guidance" className="mx-auto mb-4" />
            <h4 className="text-xl font-bold mb-2">Visa Guidance</h4>
            <p className="mb-4">
              Need to apply for a Visa? When should you apply for the student
              visa? How do you apply for the student visa?
            </p>
            <Link to="/visaguidance" className="bg-[#eb4934] px-4 py-2 text-white font-bold rounded inline-block hover:bg-[#d43f2b]">
              Know More
            </Link>
          </div>
        </div>
        <TestPrep />
        <Destination />
        <CounterSection />
        <ArticlesGridHome />
        <BranchGridHome />
        <EducationalConsultancy />
        <FAQ />
      </div>
    </div>
  );
}

export default Home;
