// SlideData.js
export const slideData = [
  {
    title: "IELTS(International English Language Testing System)",
    description:
      "IELTS is a test of English Language proficiency. It is jointly managed by university of Cambridge ESOL Examinations, the British Council and IDP Education Australia.",
    image: "IELTS.svg",
    link: "/testpreparation/ielts",
  },
  {
    title: "PTE-A( Pearson Test of English)",
    description:
      "PTE Academic is the world’s leading computer-based test of English for study abroad and immigration.  PTE Academic assesses listening, reading, speaking and writing in a single three-hour online test session.",
    image: "Pearson.svg",
    link: "/testpreparation/pte",
  },
  {
    title: "TOEFL(Test of English as a Foreign Language)",
    description:
      "TOEFL stands for Test of English as a Foreign Language. It measures the ability of non-native speakers of English to students utilize their skills of listening, reading, speaking and writing.",
    image: "TOEFL.svg",
    link: "/testpreparation/toefl",
  },
  {
    title: "GRE (Graduate Record Examination)",
    description:
      "GRE is a standardised aptitude exam with a pre-defined syllabus. It is one of the most widely accepted admissions tests for graduate and business school programs.",
    image: "GRE.svg",
    link: "/testpreparation/gre",
  },
  {
    title: "GMAT (Graduate Management Admission Test)",
    description: "GMAT is a standardized aptitude test with a pre-defined syllabus and is required for admission to many of the world’s leading Business Schools.",
    image: "GMAT.svg",
    link: "/testpreparation/gmat",
  },
  {
    title: "SAT (Scholastic Aptitude Test)",
    description: "SAT is a standardized test that is designed to test critical thinking skills,the ability to analyze and solve problems in math, critical reading, and writing.SAT is one way to demonstrate a student’s competitiveness.",
    image: "SAT.svg",
    link: "/testpreparation/sat",
  },
];
