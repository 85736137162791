import { Link } from "react-router-dom";

function VisaGuidance() {
    return (
        <div className="container mx-auto max-w-7xl p-6">
            <h1 className="text-4xl font-bold text-center mb-6">Comprehensive Visa Guidance to Study Abroad</h1>

            {/* Introduction */}
            <section className="mb-8">
                <p className="text-lg">
                    Applying for a student visa can be a complex process, but with proper guidance, you can navigate it smoothly.
                    Below, we provide a detailed guide covering visa types, requirements, application steps, interview tips, and common FAQs for various countries.
                </p>
            </section>

            {/* Types of Student Visas */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Types of Student Visas</h2>
                <p>Different countries offer different types of student visas. Here’s an overview:</p>
                <ul className="list-disc pl-6 mt-3">
                    <li><strong>USA</strong>: F-1 (Academic), J-1 (Exchange Programs), M-1 (Vocational)</li>
                    <li><strong>UK</strong>: Student Visa (Tier 4)</li>
                    <li><strong>Canada</strong>: Study Permit</li>
                    <li><strong>Australia</strong>: Subclass 500</li>
                    <li><strong>Schengen Area (Europe)</strong>: National Long-Stay Visa</li>
                    <li><strong>New Zealand</strong>: Fee-Paying Student Visa</li>
                    <li><strong>Germany</strong>: Student Applicant Visa, National Visa</li>
                </ul>
            </section>

            {/* General Visa Requirements */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">General Visa Requirements</h2>
                <p>Most student visa applications require the following:</p>
                <ul className="list-disc pl-6 mt-3">
                    <li>Valid passport (minimum 6 months validity beyond the intended stay)</li>
                    <li>Offer letter from a recognized educational institution</li>
                    <li>Completed visa application form</li>
                    <li>Proof of financial support (bank statements, sponsorship letter, or scholarship proof)</li>
                    <li>Tuition fee payment receipt</li>
                    <li>Medical examination (if required)</li>
                    <li>Language proficiency test results (IELTS, TOEFL, PTE, etc.)</li>
                    <li>Statement of Purpose (SOP) explaining your study plans</li>
                </ul>
            </section>

            {/* Step-by-Step Application Guide */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Step-by-Step Visa Application Process</h2>
                <ol className="list-decimal pl-6 mt-3">
                    <li>Choose the country and institution for your studies.</li>
                    <li>Receive an official offer letter from the university/college.</li>
                    <li>Gather all necessary documents.</li>
                    <li>Complete the visa application form online or via the embassy.</li>
                    <li>Pay the visa application fee.</li>
                    <li>Schedule a visa interview (if required).</li>
                    <li>Attend the interview with all necessary documents.</li>
                    <li>Wait for visa approval and collect your visa.</li>
                    <li>Plan your travel and ensure compliance with entry requirements.</li>
                </ol>
            </section>

            {/* Visa Interview Tips */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Visa Interview Tips</h2>
                <p>Many countries require students to attend an interview as part of the visa process. Here’s how you can prepare:</p>
                <ul className="list-disc pl-6 mt-3">
                    <li><strong>Be honest:</strong> Provide truthful answers and documents.</li>
                    <li><strong>Know your course details:</strong> Be prepared to explain why you chose this course and institution.</li>
                    <li><strong>Show strong ties to your home country:</strong> Demonstrate that you plan to return after your studies.</li>
                    <li><strong>Prepare for common questions:</strong> Expect questions about funding, career goals, and post-study plans.</li>
                    <li><strong>Dress professionally:</strong> Appear neat and confident.</li>
                </ul>
            </section>

            {/* Country-Specific Visa Processing Times */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Visa Processing Times by Country</h2>
                <p>Visa processing times vary by country and season. Here are estimated processing times:</p>
                <ul className="list-disc pl-6 mt-3">
                    <li><strong>USA:</strong> 3-6 weeks</li>
                    <li><strong>UK:</strong> 3-4 weeks</li>
                    <li><strong>Canada:</strong> 4-8 weeks</li>
                    <li><strong>Australia:</strong> 4-6 weeks</li>
                    <li><strong>Germany:</strong> 6-12 weeks</li>
                    <li><strong>New Zealand:</strong> 6-10 weeks</li>
                </ul>
                <p className="mt-2"><strong>Note:</strong> Processing times may vary due to COVID-19, holidays, or additional document requirements.</p>
            </section>

            {/* Frequently Asked Questions (FAQ) */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Frequently Asked Questions (FAQ)</h2>
                <div className="bg-gray-100 p-4 rounded-md">
                    <p><strong>Q: Can I work while studying abroad?</strong></p>
                    <p>A: Yes, most student visas allow part-time work (e.g., 20 hours/week in the USA, UK, Australia, Canada).</p>
                </div>
                <div className="bg-gray-100 p-4 rounded-md mt-3">
                    <p><strong>Q: What happens if my visa is rejected?</strong></p>
                    <p>A: You can reapply after identifying the reason for rejection. Consult an expert if needed.</p>
                </div>
                <div className="bg-gray-100 p-4 rounded-md mt-3">
                    <p><strong>Q: Do I need health insurance?</strong></p>
                    <p>A: Yes, many countries require proof of health insurance before issuing a student visa.</p>
                </div>
            </section>

            {/* Conclusion */}
            <section className="mt-8 text-center">
                <h2 className="text-3xl font-semibold mb-4">Need More Help?</h2>
                <p>If you need personalized guidance on visa applications, feel free to contact our consultancy team.</p>
                <Link to="/contact-us">
                    <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold p-2 mt-3 rounded">
                        Contact Us
                    </button>
                </Link>
            </section>
        </div>
    );
}

export default VisaGuidance;
