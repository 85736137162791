import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import axiosInstance from "../../api/axiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone,faGlobe,faUser,faEnvelope,faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const BranchDetails = ({ branch }) => {
  // Component logic for BranchDetails
  if (!branch) {
    return <div className="p-6">Branch details not available</div>;
  }
  return (
    <div className="p-6 mx-auto max-w-screen-6xl">
      {/* Branch Details Section */}
      {branch.length === 0 ? (
        <div className="text-center text-gray-500 text-lg">
          There is no branch to display.
        </div>
      ) : (
        <div className="bg-white p-6 rounded-lg shadow-md flex flex-wrap mb-6 relative">
       <div className="w-full lg:w-1/2 lg:pr-6 mb-6 lg:mb-0">
         <h3 className="text-lg sm:text-xl font-semibold flex-grow mb-2">
           {branch.branch_name}
         </h3>
         <div className="text-left text-sm sm:text-base">
           <p className="text-green-600 mb-2 flex items-center">
             <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
             <a href={branch.map_link} target="_blank" rel="noopener noreferrer" className="underline">
               {branch.city}
             </a>
           </p>
           <p className="text-gray-800 font-semibold flex items-center mb-1">
             <FontAwesomeIcon icon={faGlobe} className="mr-2" />
             Country: <span className="ml-1">{branch.country}</span>
           </p>
           <p className="text-gray-600 mb-4">{branch.desc}</p>
           <p className="text-gray-800 font-semibold flex items-center mb-1">
             <FontAwesomeIcon icon={faUser} className="mr-2" />
             Branch Manager: <span className="ml-1">{branch.manager_name}</span>
           </p>
           <ul className="list-none space-y-1 mb-4">
             <li className="flex items-center">
               <FontAwesomeIcon icon={faPhone} className="mr-2" />
               <span className="font-semibold">Manager Phone:</span>
               <a href={`tel:${branch.manager_phone_number}`} className="text-blue-500 hover:underline ml-1">
                 {branch.manager_phone_number}
               </a>
             </li>
             <li className="flex items-center">
               <FontAwesomeIcon icon={faPhone} className="mr-2" />
               <span className="font-semibold">Phone:</span>
               <a href={`tel:${branch.phone_number}`} className="text-blue-500 hover:underline ml-1">
                 {branch.phone_number}
               </a>
             </li>
             <li className="flex items-center">
               <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
               <span className="font-semibold">Email:</span>
               <a href={`mailto:${branch.email}`} className="text-blue-500 hover:underline ml-1">
                 {branch.email}
               </a>
             </li>
           </ul>
         </div>
       </div>
       <div className="w-full lg:w-1/2">
         <iframe
           src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(branch.map_link)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
           title="Branch Location Map"
           className="w-full h-full rounded-lg border border-gray-200"
           loading="lazy"
           referrerPolicy="no-referrer-when-downgrade"
           aria-label={`Map showing the location of ${branch.name}`}
         ></iframe>
       </div>
        </div>
      )}
    </div>
  );
};

const BranchHomePage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 12;
  useEffect(() => {
    axiosInstance.get('/api/users/branches/').then(response => {
      setLoading(false);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setBranches(response.data.data.slice(startIndex, endIndex));
    }).catch(error => {
      setLoading(false);
      setError("something went wrong.")
      console.log(error)
    })
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const totalPages = Math.ceil(branches.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6">
      <header className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h1 className="text-2xl sm:text-3xl font-bold">Branch Details</h1>
        {/* Removed the Add Branch button */}
      </header>

      <div className="flex flex-col gap-6">
        {loading && !error && branches.length === 0 ? (
          <div className="text-center text-gray-500 bg-gray-500/10 py-20">
            Loading branches...
          </div>
        ) : error && !loading && branches.length === 0 ? (
          <div className="text-center text-red-500 bg-red-500/10 py-20">
            Error loading branches: {error.message || 'Something went wrong.'}
          </div>
        ) : !error && !loading && branches.length > 0 ? (
          branches.map((branch) => (
            <TransitionGroup key={branch.id}>
              <CSSTransition timeout={500} classNames="fade">
                <BranchDetails branch={branch} />
              </CSSTransition>
            </TransitionGroup>
          ))
        ) : (
          <div className="text-center text-gray-500 bg-gray-500/10 py-20">
            There is no branch to display.
          </div>
        )}


      </div>

      <div className="flex justify-center gap-2 mt-6">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            aria-label={`Page ${index + 1}`}
            className={`px-4 py-2 border rounded ${currentPage === index + 1
              ? "bg-[#eb4934] hover:bg-[#d43b2e] text-white"
              : "bg-white text-[#eb4934]"
              }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BranchHomePage;

