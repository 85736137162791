import React from 'react';
import { Link } from 'react-router-dom';

function TestInfo({ testName, testDescription, structure, scoring, eligibility, registration, testDuration, additionalDetails }) {
    return (
        <div className="p-6 max-w-7xl mx-auto bg-white rounded-2xl space-y-4">
            <h1 className="text-2xl font-bold text-gray-800">{testName} Exam Information</h1>
            <p className="text-gray-600">{testDescription}</p>
            <h2 className="text-xl font-semibold text-gray-700">Exam Structure</h2>
            <ul className="list-disc pl-5 text-gray-600">
                {structure.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
            <h2 className="text-xl font-semibold text-gray-700">Scoring</h2>
            <p className="text-gray-600">{scoring}</p>
            <h2 className="text-xl font-semibold text-gray-700">Eligibility</h2>
            <p className="text-gray-600">{eligibility}</p>
            <h2 className="text-xl font-semibold text-gray-700">Registration</h2>
            <p className="text-gray-600">{registration}</p>
            <h2 className="text-xl font-semibold text-gray-700">Test Duration</h2>
            <p className="text-gray-600">{testDuration}</p>
            {additionalDetails && (
                <>
                    <h2 className="text-xl font-semibold text-gray-700">Additional Details</h2>
                    <p className="text-gray-600">{additionalDetails}</p>
                </>
            )}
            <Link to="/make-an-enquiry">
                <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold p-2 mt-3 rounded">
                    Start Your Application
                </button>
            </Link>
        </div>
    );
}

export function GMATInfo() {
    return (
        <TestInfo
            testName="GMAT"
            testDescription="The Graduate Management Admission Test (GMAT) is a standardized exam used for admissions to business schools worldwide. It evaluates critical thinking, quantitative and verbal reasoning, and writing skills."
            structure={[
                'Analytical Writing Assessment (AWA): 1 essay',
                'Integrated Reasoning: 12 questions',
                'Quantitative Reasoning: 31 questions',
                'Verbal Reasoning: 36 questions'
            ]}
            scoring="The GMAT score ranges from 200 to 800, with the Verbal and Quantitative Reasoning sections contributing the most to the overall score. The AWA is scored separately from 0 to 6, and Integrated Reasoning is scored from 1 to 8."
            eligibility="There are no specific eligibility criteria, but candidates must be at least 18 years old. Candidates under 18 can take the test with parental consent. There is no maximum age for the GMAT."
            registration="Candidates can register for the GMAT online at the official GMAT website. Early registration is recommended to secure your preferred test date and center."
            testDuration="The GMAT exam takes approximately 3 hours and 7 minutes to complete, including a short break."
            additionalDetails="The GMAT is a computer-adaptive test, meaning the difficulty of questions adjusts based on your performance. It can be taken up to 5 times per year, but there must be a 16-day gap between retakes. Scores are valid for 5 years."
        />
    );
}

export function IELTSInfo() {
    return (
        <TestInfo
            testName="IELTS"
            testDescription="The International English Language Testing System (IELTS) is a widely recognized English language proficiency test used for university admissions, employment, and migration purposes."
            structure={[
                'Listening: 4 sections (30 minutes)',
                'Reading: 3 sections (60 minutes)',
                'Writing: 2 tasks (60 minutes)',
                'Speaking: 3 parts (11-14 minutes)'
            ]}
            scoring="IELTS is scored on a scale from 1 to 9, with each section scored individually. The overall score is the average of all section scores, rounded to the nearest half band."
            eligibility="There are no specific eligibility requirements, but candidates should have a basic understanding of English. The minimum age for test-takers is 16."
            registration="You can register online or at an authorized IELTS test center. Early registration is encouraged to ensure preferred dates and locations."
            testDuration="The IELTS exam takes approximately 2 hours and 45 minutes to complete. The Speaking test may be scheduled separately."
            additionalDetails="The IELTS test is available in two versions: Academic (for higher education) and General Training (for migration and work purposes). It is available up to four times a month at various centers worldwide."
        />
    );
}

export function TOEFLInfo() {
    return (
        <TestInfo
            testName="TOEFL"
            testDescription="The Test of English as a Foreign Language (TOEFL) measures a student's ability to understand and use English in academic settings, commonly used for university admissions worldwide."
            structure={[
                'Reading: 3-4 passages (60-80 minutes)',
                'Listening: 3-4 lectures (60-90 minutes)',
                'Speaking: 6 tasks (20 minutes)',
                'Writing: 2 tasks (50 minutes)'
            ]}
            scoring="TOEFL scores range from 0 to 120, with each section scored from 0 to 30. The combined score is the sum of the individual section scores."
            eligibility="There are no specific eligibility criteria, but it is recommended that test-takers have a basic command of English. There is no minimum age limit."
            registration="Register for TOEFL online at the official website. It is recommended to register 4-6 weeks before the test date."
            testDuration="TOEFL takes about 3 hours to complete. Additional time is needed for the Speaking test and for reporting scores."
            additionalDetails="TOEFL is available in both internet-based (iBT) and paper-based formats, with the iBT being more widely available. Scores are typically available 6 days after the exam."
        />
    );
}

export function GREInfo() {
    return (
        <TestInfo
            testName="GRE"
            testDescription="The Graduate Record Examinations (GRE) is a standardized test used for admissions to graduate schools. It tests verbal reasoning, quantitative reasoning, and analytical writing skills."
            structure={[
                'Verbal Reasoning: 40 questions (35 minutes)',
                'Quantitative Reasoning: 40 questions (35 minutes)',
                'Analytical Writing: 2 tasks (60 minutes)'
            ]}
            scoring="The GRE scores range from 130 to 170 for the verbal and quantitative reasoning sections, and 0 to 6 for the analytical writing section."
            eligibility="There are no specific eligibility criteria, but the GRE is typically taken by individuals applying to graduate or business schools."
            registration="Candidates can register online through the official GRE website. Registration should be done at least 3 weeks before your intended test date."
            testDuration="The GRE takes approximately 3 hours and 45 minutes, including breaks."
            additionalDetails="The GRE offers both general and subject tests. General tests are available year-round, while subject tests are offered a few times per year. The computer-based GRE is widely available."
        />
    );
}
export function SATInfo() {
    return (
        <TestInfo
            testName="SAT"
            testDescription="The Scholastic Assessment Test (SAT) is a standardized test used for college admissions in the United States. It measures a student's academic readiness for college."
            structure={[
                'Reading: 52 questions (65 minutes)',
                'Writing and Language: 44 questions (35 minutes)',
                'Mathematics: 58 questions (80 minutes)',
                'Essay (optional): 1 task (50 minutes)'
            ]}
            scoring="The SAT score ranges from 400 to 1600, combining the scores from the Math and Evidence-Based Reading and Writing sections. The Essay is scored separately."
            eligibility="There are no specific eligibility requirements, but most students take the SAT in high school as part of their college application process."
            registration="You can register online via the College Board website. Registration should be done early to secure a spot at your preferred testing center."
            testDuration="The SAT takes about 3 hours without the essay, or 3 hours and 50 minutes with the essay."
            additionalDetails="The SAT is offered several times a year, and colleges use it to assess a student's preparedness for higher education. The test is composed of multiple-choice questions and a math section with a calculator and non-calculator part."
        />
    );
}

export function PTEInfo() {
    return (
        <TestInfo
            testName="PTE"
            testDescription="The Pearson Test of English (PTE) is an English language proficiency test widely used by universities, colleges, and immigration authorities. It assesses listening, speaking, reading, and writing skills."
            structure={[
                'Speaking and Writing: 77-93 minutes',
                'Reading: 32-41 minutes',
                'Listening: 45-57 minutes'
            ]}
            scoring="PTE scores range from 10 to 90, with individual scores for each section and an overall score."
            eligibility="No specific eligibility requirements. PTE is often used by those looking to study abroad or immigrate to English-speaking countries."
            registration="You can register for PTE through the official Pearson website. The test is available year-round."
            testDuration="The PTE exam takes about 3 hours to complete, including a short break."
            additionalDetails="The PTE Academic test is computer-based and provides results within 5 business days. It is accepted by thousands of academic institutions worldwide."
        />
    );
}
const TestInfoComponents = { GMATInfo, IELTSInfo, TOEFLInfo, GREInfo, SATInfo, PTEInfo };

export default TestInfoComponents;
