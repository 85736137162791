import { Link } from 'react-router-dom';

function CareerCounselling() {
    return (
        <div className="container mx-auto max-w-7xl p-6">
            <h1 className="text-4xl font-bold text-center mb-6">Expert Career Counselling for a Bright Future</h1>

            {/* Introduction */}
            <section className="mb-8">
                <p className="text-lg">
                    Choosing the right career path is one of the most important decisions in life. Our expert career counsellors
                    provide personalized guidance to help students and professionals discover their ideal career paths,
                    select the right courses, and achieve long-term career goals.
                </p>
            </section>

            {/* Why Career Counselling? */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Why is Career Counselling Important?</h2>
                <ul className="list-disc pl-6 mt-3">
                    <li>Helps in choosing a career that aligns with your interests and skills.</li>
                    <li>Provides clarity about job opportunities in different fields.</li>
                    <li>Guides students in selecting the right courses and universities.</li>
                    <li>Assists professionals in making career transitions smoothly.</li>
                    <li>Offers insights into industry trends and emerging job markets.</li>
                </ul>
            </section>

            {/* Career Counselling Services */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Our Career Counselling Services</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="text-xl font-semibold">🔍 Career Assessment</h3>
                        <p>We use scientifically designed aptitude, personality, and interest assessments to help you find the best career options.</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="text-xl font-semibold">🎓 Course & University Selection</h3>
                        <p>Guidance on choosing the right courses and universities based on your career goals.</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="text-xl font-semibold">📈 Career Growth & Planning</h3>
                        <p>Strategic career planning for students and professionals looking for long-term success.</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="text-xl font-semibold">💼 Resume & Interview Preparation</h3>
                        <p>Get expert guidance on resume writing, LinkedIn profile building, and interview techniques.</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="text-xl font-semibold">🌍 Study Abroad & Career Opportunities</h3>
                        <p>Explore international study and work opportunities to advance your career.</p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-md">
                        <h3 className="text-xl font-semibold">🛠 Skill Development</h3>
                        <p>We offer skill development programs to enhance employability and career growth.</p>
                    </div>
                </div>
            </section>

            {/* Career Assessment Process */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Our Career Assessment Process</h2>
                <p>We follow a structured approach to career assessment and planning:</p>
                <ol className="list-decimal pl-6 mt-3">
                    <li><strong>Self-Assessment:</strong> Understanding interests, skills, and values.</li>
                    <li><strong>Aptitude & Personality Tests:</strong> Scientifically backed assessments to determine strengths.</li>
                    <li><strong>Exploring Career Options:</strong> Detailed analysis of suitable career paths.</li>
                    <li><strong>Education & Skill Recommendations:</strong> Guidance on courses and certifications.</li>
                    <li><strong>Personalized Career Roadmap:</strong> A step-by-step guide to achieving career goals.</li>
                </ol>
            </section>

            {/* Industry-Specific Career Guidance */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Industry-Specific Career Guidance</h2>
                <p>We offer career counselling tailored to various industries, including:</p>
                <ul className="list-disc pl-6 mt-3">
                    <li>💻 Information Technology (Software Development, AI, Cybersecurity)</li>
                    <li>⚕️ Healthcare & Medicine (Doctors, Nursing, Pharmacy)</li>
                    <li>📈 Business & Finance (Accounting, Investment Banking, Entrepreneurship)</li>
                    <li>🎨 Creative & Design (Graphic Design, Animation, UI/UX, Digital Marketing)</li>
                    <li>🛠 Engineering & Manufacturing (Mechanical, Civil, Electrical, Robotics)</li>
                    <li>📡 Media & Communication (Journalism, Advertising, Public Relations)</li>
                    <li>🌱 Environmental Science & Sustainability</li>
                </ul>
            </section>

            {/* Career FAQs */}
            <section className="mb-8">
                <h2 className="text-3xl font-semibold mb-4">Frequently Asked Questions (FAQs)</h2>
                <div className="bg-gray-100 p-4 rounded-md">
                    <p><strong>Q: Who should seek career counselling?</strong></p>
                    <p>A: Anyone facing career confusion, students choosing courses, or professionals looking to switch careers.</p>
                </div>
                <div className="bg-gray-100 p-4 rounded-md mt-3">
                    <p><strong>Q: How do career assessments help?</strong></p>
                    <p>A: They identify your strengths, weaknesses, and interests, helping you make informed career choices.</p>
                </div>
                <div className="bg-gray-100 p-4 rounded-md mt-3">
                    <p><strong>Q: Can career counselling help me switch careers?</strong></p>
                    <p>A: Yes! We provide strategies to transition into new industries with skill development and certification guidance.</p>
                </div>
                <div className="bg-gray-100 p-4 rounded-md mt-3">
                    <p><strong>Q: Do you offer online career counselling?</strong></p>
                    <p>A: Yes, we offer virtual career counselling sessions worldwide.</p>
                </div>
            </section>

            {/* Conclusion */}
            <section className="mt-8 text-center">
                <h2 className="text-3xl font-semibold mb-4">Start Your Career Journey with Us</h2>
                <p>Our expert career counsellors are ready to help you achieve your goals. Book a consultation today!</p>
                <Link to="/contact-us">
                    <button className="bg-[#eb4934] hover:bg-[#d43b2e] text-white font-semibold p-2 mt-3 rounded">
                        Contact Us
                    </button>
                </Link>
            </section>
        </div>
    );
}

export default CareerCounselling;
