const Destinations = [
  {
    id: 1,
    name: "Australia",
    image: "australia.jpg",
    description:
      "If you are interested in studying in Australia, you are taking one step closer towards choosing a truly fruitful academic experience.",
    link: "/study-abroad/australia",
    universities: [
      {
        name: "University of Sydney",
        rank: "40th worldwide",
        location: "Sydney, New South Wales",
        link: "https://www.sydney.edu.au/",
      },
      {
        name: "Australian National University",
        rank: "31st worldwide",
        location: "Canberra, Australian Capital Territory",
        link: "https://www.anu.edu.au/",
      },
      {
        name: "University of Melbourne",
        rank: "33rd worldwide",
        location: "Melbourne, Victoria",
        link: "https://www.unimelb.edu.au/",
      },
      {
        name: "University of Queensland",
        rank: "43rd worldwide",
        location: "Brisbane, Queensland",
        link: "https://www.uq.edu.au/",
      },
      {
        name: "University of New South Wales (UNSW)",
        rank: "44th worldwide",
        location: "Sydney, New South Wales",
        link: "https://www.unsw.edu.au/",
      },
      {
        name: "Monash University",
        rank: "57th worldwide",
        location: "Melbourne, Victoria",
        link: "https://www.monash.edu/",
      },
      {
        name: "University of Western Australia",
        rank: "86th worldwide",
        location: "Perth, Western Australia",
        link: "https://www.uwa.edu.au/",
      },
      {
        name: "University of Adelaide",
        rank: "106th worldwide",
        location: "Adelaide, South Australia",
        link: "https://www.adelaide.edu.au/",
      },
      {
        name: "University of Technology Sydney (UTS)",
        rank: "143rd worldwide",
        location: "Sydney, New South Wales",
        link: "https://www.uts.edu.au/",
      },
      {
        name: "Macquarie University",
        rank: "195th worldwide",
        location: "Sydney, New South Wales",
        link: "https://www.mq.edu.au/",
      },
      {
        name: "University of Wollongong",
        rank: "212th worldwide",
        location: "Wollongong, New South Wales",
        link: "https://www.uow.edu.au/",
      },
      {
        name: "University of Newcastle",
        rank: "207th worldwide",
        location: "Newcastle, New South Wales",
        link: "https://www.newcastle.edu.au/",
      },
      {
        name: "Queensland University of Technology (QUT)",
        rank: "222nd worldwide",
        location: "Brisbane, Queensland",
        link: "https://www.qut.edu.au/",
      },
      {
        name: "University of Tasmania",
        rank: "303rd worldwide",
        location: "Hobart, Tasmania",
        link: "https://www.utas.edu.au/",
      },
      {
        name: "Griffith University",
        rank: "290th worldwide",
        location: "Brisbane, Queensland",
        link: "https://www.griffith.edu.au/",
      },
      {
        name: "Curtin University",
        rank: "217th worldwide",
        location: "Perth, Western Australia",
        link: "https://www.curtin.edu.au/",
      },
      {
        name: "RMIT University",
        rank: "206th worldwide",
        location: "Melbourne, Victoria",
        link: "https://www.rmit.edu.au/",
      },
      {
        name: "Deakin University",
        rank: "251st worldwide",
        location: "Melbourne, Victoria",
        link: "https://www.deakin.edu.au/",
      },
      {
        name: "La Trobe University",
        rank: "398th worldwide",
        location: "Melbourne, Victoria",
        link: "https://www.latrobe.edu.au/",
      },
      {
        name: "University of South Australia",
        rank: "363rd worldwide",
        location: "Adelaide, South Australia",
        link: "https://www.unisa.edu.au/",
      },
      {
        name: "University of Canberra",
        rank: "456th worldwide",
        location: "Canberra, Australian Capital Territory",
        link: "https://www.canberra.edu.au/",
      },
      {
        name: "Bond University",
        rank: "591st worldwide",
        location: "Gold Coast, Queensland",
        link: "https://bond.edu.au/",
      },
      {
        name: "Flinders University",
        rank: "379th worldwide",
        location: "Adelaide, South Australia",
        link: "https://www.flinders.edu.au/",
      },
      {
        name: "Swinburne University of Technology",
        rank: "321st worldwide",
        location: "Melbourne, Victoria",
        link: "https://www.swinburne.edu.au/",
      },
      {
        name: "Charles Darwin University",
        rank: "801-1000th worldwide",
        location: "Darwin, Northern Territory",
        link: "https://www.cdu.edu.au/",
      },
      {
        name: "Victoria University",
        rank: "501-600th worldwide",
        location: "Melbourne, Victoria",
        link: "https://www.vu.edu.au/",
      },
      {
        name: "James Cook University",
        rank: "461st worldwide",
        location: "Townsville, Queensland",
        link: "https://www.jcu.edu.au/",
      },
      {
        name: "Murdoch University",
        rank: "551-600th worldwide",
        location: "Perth, Western Australia",
        link: "https://www.murdoch.edu.au/",
      },
      {
        name: "Southern Cross University",
        rank: "601-650th worldwide",
        location: "Lismore, New South Wales",
        link: "https://www.scu.edu.au/",
      },
      {
        name: "University of New England",
        rank: "801-1000th worldwide",
        location: "Armidale, New South Wales",
        link: "https://www.une.edu.au/",
      },
      {
        name: "Western Sydney University",
        rank: "474th worldwide",
        location: "Sydney, New South Wales",
        link: "https://www.westernsydney.edu.au/",
      },
      {
        name: "University of Southern Queensland",
        rank: "701-750th worldwide",
        location: "Toowoomba, Queensland",
        link: "https://www.usq.edu.au/",
      },
      {
        name: "Australian Catholic University",
        rank: "801-1000th worldwide",
        location: "Brisbane, Queensland",
        link: "https://www.acu.edu.au/",
      },
      {
        name: "Central Queensland University",
        rank: "601-650th worldwide",
        location: "Rockhampton, Queensland",
        link: "https://www.cqu.edu.au/",
      },
      {
        name: "Edith Cowan University",
        rank: "651-700th worldwide",
        location: "Perth, Western Australia",
        link: "https://www.ecu.edu.au/",
      },
      {
        name: "University of Sunshine Coast",
        rank: "751-800th worldwide",
        location: "Sunshine Coast, Queensland",
        link: "https://www.usc.edu.au/",
      },
    ],
  },
  {
    id: 2,
    name: "USA",
    image: "america.jpg",
    description:
      "The United States is a popular destination for international students seeking a diverse and comprehensive education.",
    link: "/study-abroad/united-states-of-america",
    universities: [
      {
        name: "Harvard University",
        rank: "3rd worldwide",
        location: "Cambridge, Massachusetts",
        link: "https://www.harvard.edu/",
      },
      {
        name: "Stanford University",
        rank: "2nd worldwide",
        location: "Stanford, California",
        link: "https://www.stanford.edu/",
      },
      {
        name: "MIT",
        rank: "1st worldwide",
        location: "Cambridge, Massachusetts",
        link: "https://www.mit.edu/",
      },
      {
        name: "Caltech",
        rank: "6th worldwide",
        location: "Pasadena, California",
        link: "https://www.caltech.edu/",
      },
      {
        name: "University of Chicago",
        rank: "10th worldwide",
        location: "Chicago, Illinois",
        link: "https://www.uchicago.edu/",
      },
      {
        name: "Princeton University",
        rank: "16th worldwide",
        location: "Princeton, New Jersey",
        link: "https://www.princeton.edu/",
      },
      {
        name: "Columbia University",
        rank: "18th worldwide",
        location: "New York City, New York",
        link: "https://www.columbia.edu/",
      },
      {
        name: "Yale University",
        rank: "14th worldwide",
        location: "New Haven, Connecticut",
        link: "https://www.yale.edu/",
      },
      {
        name: "University of Pennsylvania",
        rank: "13th worldwide",
        location: "Philadelphia, Pennsylvania",
        link: "https://www.upenn.edu/",
      },
      {
        name: "UC Berkeley",
        rank: "32nd worldwide",
        location: "Berkeley, California",
        link: "https://www.berkeley.edu/",
      },
      {
        name: "UCLA",
        rank: "40th worldwide",
        location: "Los Angeles, California",
        link: "https://www.ucla.edu/",
      },
      {
        name: "UC San Diego",
        rank: "52nd worldwide",
        location: "La Jolla, California",
        link: "https://www.ucsd.edu/",
      },
      {
        name: "University of Michigan, Ann Arbor",
        rank: "25th worldwide",
        location: "Ann Arbor, Michigan",
        link: "https://umich.edu/",
      },
      {
        name: "UC San Francisco",
        rank: "16th worldwide",
        location: "San Francisco, California",
        link: "https://www.ucsf.edu/",
      },
      {
        name: "New York University",
        rank: "39th worldwide",
        location: "New York City, New York",
        link: "https://www.nyu.edu/",
      },
      {
        name: "University of Washington",
        rank: "59th worldwide",
        location: "Seattle, Washington",
        link: "https://www.washington.edu/",
      },
      {
        name: "Brown University",
        rank: "61st worldwide",
        location: "Providence, Rhode Island",
        link: "https://www.brown.edu/",
      },
      {
        name: "Dartmouth College",
        rank: "205th worldwide",
        location: "Hanover, New Hampshire",
        link: "https://home.dartmouth.edu/",
      },
      {
        name: "Vanderbilt University",
        rank: "198th worldwide",
        location: "Nashville, Tennessee",
        link: "https://www.vanderbilt.edu/",
      },
      {
        name: "Duke University",
        rank: "52nd worldwide",
        location: "Durham, North Carolina",
        link: "https://www.duke.edu/",
      },
      {
        name: "Northwestern University",
        rank: "40th worldwide",
        location: "Evanston, Illinois",
        link: "https://www.northwestern.edu/",
      },
      {
        name: "Cornell University",
        rank: "21st worldwide",
        location: "Ithaca, New York",
        link: "https://www.cornell.edu/",
      },
      {
        name: "University of Notre Dame",
        rank: "178th worldwide",
        location: "Notre Dame, Indiana",
        link: "https://www.nd.edu/",
      },
      {
        name: "Georgetown University",
        rank: "248th worldwide",
        location: "Washington, D.C.",
        link: "https://www.georgetown.edu/",
      },
      {
        name: "Rice University",
        rank: "151st worldwide",
        location: "Houston, Texas",
        link: "https://www.rice.edu/",
      },
      {
        name: "Emory University",
        rank: "73rd worldwide",
        location: "Atlanta, Georgia",
        link: "https://www.emory.edu/home/index.html",
      },
      {
        name: "Carnegie Mellon University",
        rank: "28th worldwide",
        location: "Pittsburgh, Pennsylvania",
        link: "https://www.cmu.edu/",
      },
    ],
  },
  {
    id: 3,
    name: "Canada",
    image: "canada.jpg",
    description:
      "Canada is known for its high-quality education and welcoming environment.",
    link: "/study-abroad/canada",
    universities: [
      {
        name: "University of Toronto",
        rank: "26th worldwide",
        location: "Toronto, Ontario",
        link: "https://www.utoronto.ca/",
      },
      {
        name: "University of British Columbia",
        rank: "34th worldwide",
        location: "Vancouver, British Columbia",
        link: "https://www.ubc.ca/",
      },
      {
        name: "McGill University",
        rank: "27th worldwide",
        location: "Montreal, Quebec",
        link: "https://www.mcgill.ca/",
      },
      {
        name: "University of Alberta",
        rank: "113th worldwide",
        location: "Edmonton, Alberta",
        link: "https://www.ualberta.ca/",
      },
      {
        name: "University of Waterloo",
        rank: "149th worldwide",
        location: "Waterloo, Ontario",
        link: "https://uwaterloo.ca/",
      },
      {
        name: "University of Calgary",
        rank: "169th worldwide",
        location: "Calgary, Alberta",
        link: "https://www.ucalgary.ca/",
      },
      {
        name: "Western University",
        rank: "214th worldwide",
        location: "London, Ontario",
        link: "https://www.uwo.ca/",
      },
      {
        name: "McMaster University",
        rank: "140th worldwide",
        location: "Hamilton, Ontario",
        link: "https://www.mcmaster.ca/",
      },
      {
        name: "Queen's University",
        rank: "246th worldwide",
        location: "Kingston, Ontario",
        link: "https://www.queensu.ca/",
      },
      {
        name: "Simon Fraser University",
        rank: "328th worldwide",
        location: "Burnaby, British Columbia",
        link: "https://www.sfu.ca/",
      },
      {
        name: "Dalhousie University",
        rank: "291st worldwide",
        location: "Halifax, Nova Scotia",
        link: "https://www.dal.ca/",
      },
      {
        name: "University of Ottawa",
        rank: "279th worldwide",
        location: "Ottawa, Ontario",
        link: "https://www.uottawa.ca/",
      },
      {
        name: "Laval University",
        rank: "420th worldwide",
        location: "Quebec City, Quebec",
        link: "https://www.ulaval.ca/",
      },
      {
        name: "Université de Montréal",
        rank: "125th worldwide",
        location: "Montreal, Quebec",
        link: "https://www.umontreal.ca/",
      },
      {
        name: "University of Victoria",
        rank: "301st worldwide",
        location: "Victoria, British Columbia",
        link: "https://www.uvic.ca/",
      },
      {
        name: "York University",
        rank: "531st worldwide",
        location: "Toronto, Ontario",
        link: "https://www.yorku.ca/",
      },
      {
        name: "University of Saskatchewan",
        rank: "452nd worldwide",
        location: "Saskatoon, Saskatchewan",
        link: "https://www.usask.ca/",
      },
      {
        name: "Concordia University",
        rank: "511-520th worldwide",
        location: "Montreal, Quebec",
        link: "https://www.concordia.ca/",
      },
      {
        name: "Université de Sherbrooke",
        rank: "651-700th worldwide",
        location: "Sherbrooke, Quebec",
        link: "https://www.usherbrooke.ca/",
      },
      {
        name: "University of Guelph",
        rank: "581-590th worldwide",
        location: "Guelph, Ontario",
        link: "https://www.uoguelph.ca/",
      },
      {
        name: "University of Manitoba",
        rank: "601-650th worldwide",
        location: "Winnipeg, Manitoba",
        link: "https://umanitoba.ca/",
      },
      {
        name: "University of New Brunswick",
        rank: "801-1000th worldwide",
        location: "Fredericton, New Brunswick",
        link: "https://www.unb.ca/",
      },
      {
        name: "Ryerson University",
        rank: "801-1000th worldwide",
        location: "Toronto, Ontario",
        link: "https://www.ryerson.ca/",
      },
      {
        name: "University of Windsor",
        rank: "801-1000th worldwide",
        location: "Windsor, Ontario",
        link: "https://www.uwindsor.ca/",
      },
      {
        name: "Memorial University of Newfoundland",
        rank: "701-750th worldwide",
        location: "St. John's, Newfoundland and Labrador",
        link: "https://www.mun.ca/",
      },
      {
        name: "University of Regina",
        rank: "801-1000th worldwide",
        location: "Regina, Saskatchewan",
        link: "https://www.uregina.ca/",
      },
      {
        name: "Brock University",
        rank: "1001-1200th worldwide",
        location: "St. Catharines, Ontario",
        link: "https://brocku.ca/",
      },
      {
        name: "Carleton University",
        rank: "601-650th worldwide",
        location: "Ottawa, Ontario",
        link: "https://carleton.ca/",
      },
      {
        name: "Lakehead University",
        rank: "1001-1200th worldwide",
        location: "Thunder Bay, Ontario",
        link: "https://www.lakeheadu.ca/",
      },
      {
        name: "Laurentian University",
        rank: "1001-1200th worldwide",
        location: "Sudbury, Ontario",
        link: "https://laurentian.ca/",
      },
      {
        name: "Trent University",
        rank: "1001-1200th worldwide",
        location: "Peterborough, Ontario",
        link: "https://www.trentu.ca/",
      },
      {
        name: "University of Lethbridge",
        rank: "1001-1200th worldwide",
        location: "Lethbridge, Alberta",
        link: "https://www.ulethbridge.ca/",
      },
      {
        name: "Acadia University",
        rank: "801-1000th worldwide",
        location: "Wolfville, Nova Scotia",
        link: "https://www2.acadiau.ca/",
      },
      {
        name: "Bishop's University",
        rank: "801-1000th worldwide",
        location: "Sherbrooke, Quebec",
        link: "https://www.ubishops.ca/",
      },
      {
        name: "Mount Allison University",
        rank: "801-1000th worldwide",
        location: "Sackville, New Brunswick",
        link: "https://mta.ca/",
      },
      {
        name: "St. Francis Xavier University",
        rank: "801-1000th worldwide",
        location: "Antigonish, Nova Scotia",
        link: "https://www.stfx.ca/",
      },
    ],
  },
  {
    id: 4,
    name: "United Kingdom",
    image: "london.jpg",
    description:
      "The UK offers a world-class education with numerous opportunities to enhance your academic experience.",
    link: "/study-abroad/united-kingdom",
    universities: [
      {
        name: "University of Oxford",
        rank: "4th worldwide",
        location: "Oxford, England",
        link: "https://www.ox.ac.uk/",
      },
      {
        name: "University of Cambridge",
        rank: "8th worldwide",
        location: "Cambridge, England",
        link: "https://www.cam.ac.uk/",
      },
      {
        name: "Imperial College London",
        rank: "7th worldwide",
        location: "London, England",
        link: "https://www.imperial.ac.uk/",
      },
      {
        name: "London School of Economics (LSE)",
        rank: "29th worldwide",
        location: "London, England",
        link: "https://www.lse.ac.uk/",
      },
      {
        name: "University College London (UCL)",
        rank: "9th worldwide",
        location: "London, England",
        link: "https://www.ucl.ac.uk/",
      },
      {
        name: "University of Edinburgh",
        rank: "22nd worldwide",
        location: "Edinburgh, Scotland",
        link: "https://www.ed.ac.uk/",
      },
      {
        name: "University of Manchester",
        rank: "35th worldwide",
        location: "Manchester, England",
        link: "https://www.manchester.ac.uk/",
      },
      {
        name: "King's College London",
        rank: "37th worldwide",
        location: "London, England",
        link: "https://www.kcl.ac.uk/",
      },
      {
        name: "University of Warwick",
        rank: "64th worldwide",
        location: "Coventry, England",
        link: "https://warwick.ac.uk/",
      },
      {
        name: "University of Bristol",
        rank: "61st worldwide",
        location: "Bristol, England",
        link: "https://www.bristol.ac.uk/",
      },
      {
        name: "University of Glasgow",
        rank: "74th worldwide",
        location: "Glasgow, Scotland",
        link: "https://www.gla.ac.uk/",
      },
      {
        name: "University of Birmingham",
        rank: "90th worldwide",
        location: "Birmingham, England",
        link: "https://www.birmingham.ac.uk/",
      },
      {
        name: "University of Leeds",
        rank: "86th worldwide",
        location: "Leeds, England",
        link: "https://www.leeds.ac.uk/",
      },
      {
        name: "University of Sheffield",
        rank: "95th worldwide",
        location: "Sheffield, England",
        link: "https://www.sheffield.ac.uk/",
      },
      {
        name: "University of Southampton",
        rank: "97th worldwide",
        location: "Southampton, England",
        link: "https://www.southampton.ac.uk/",
      },
      {
        name: "Durham University",
        rank: "82nd worldwide",
        location: "Durham, England",
        link: "https://www.dur.ac.uk/",
      },
      {
        name: "University of Nottingham",
        rank: "103rd worldwide",
        location: "Nottingham, England",
        link: "https://www.nottingham.ac.uk/",
      },
      {
        name: "University of St Andrews",
        rank: "92nd worldwide",
        location: "St Andrews, Scotland",
        link: "https://www.st-andrews.ac.uk/",
      },
      {
        name: "University of Exeter",
        rank: "163rd worldwide",
        location: "Exeter, England",
        link: "https://www.exeter.ac.uk/",
      },
      {
        name: "Queen's University Belfast",
        rank: "209th worldwide",
        location: "Belfast, Northern Ireland",
        link: "https://www.qub.ac.uk/",
      },
      {
        name: "University of Liverpool",
        rank: "189th worldwide",
        location: "Liverpool, England",
        link: "https://www.liverpool.ac.uk/",
      },
      {
        name: "Newcastle University",
        rank: "134th worldwide",
        location: "Newcastle upon Tyne, England",
        link: "https://www.ncl.ac.uk/",
      },
      {
        name: "Cardiff University",
        rank: "154th worldwide",
        location: "Cardiff, Wales",
        link: "https://www.cardiff.ac.uk/",
      },
      {
        name: "Lancaster University",
        rank: "132nd worldwide",
        location: "Lancaster, England",
        link: "https://www.lancaster.ac.uk/",
      },
      {
        name: "University of Aberdeen",
        rank: "207th worldwide",
        location: "Aberdeen, Scotland",
        link: "https://www.abdn.ac.uk/",
      },
      {
        name: "University of York",
        rank: "151st worldwide",
        location: "York, England",
        link: "https://www.york.ac.uk/",
      },
      {
        name: "University of Bath",
        rank: "172nd worldwide",
        location: "Bath, England",
        link: "https://www.bath.ac.uk/",
      },
      {
        name: "University of Surrey",
        rank: "252nd worldwide",
        location: "Guildford, England",
        link: "https://www.surrey.ac.uk/",
      },
      {
        name: "University of Sussex",
        rank: "246th worldwide",
        location: "Brighton, England",
        link: "https://www.sussex.ac.uk/",
      },
      {
        name: "University of Reading",
        rank: "201st worldwide",
        location: "Reading, England",
        link: "https://www.reading.ac.uk/",
      },
      {
        name: "University of Dundee",
        rank: "317th worldwide",
        location: "Dundee, Scotland",
        link: "https://www.dundee.ac.uk/",
      },
      {
        name: "Heriot-Watt University",
        rank: "270th worldwide",
        location: "Edinburgh, Scotland",
        link: "https://www.hw.ac.uk/",
      },
      {
        name: "University of Leicester",
        rank: "258th worldwide",
        location: "Leicester, England",
        link: "https://le.ac.uk/",
      },
      {
        name: "University of Essex",
        rank: "355th worldwide",
        location: "Colchester, England",
        link: "https://www.essex.ac.uk/",
      },
      {
        name: "University of Strathclyde",
        rank: "277th worldwide",
        location: "Glasgow, Scotland",
        link: "https://www.strath.ac.uk/",
      },
      {
        name: "University of Kent",
        rank: "372nd worldwide",
        location: "Canterbury, England",
        link: "https://www.kent.ac.uk/",
      },
    ],
  },
  {
    id: 5,
    name: "New Zealand",
    image: "newzealand.jpg",
    description: "New Zealand's universities are globally recognized for quality education and research. With eight institutions, they offer diverse programs and a student-friendly environment.",
    link: "/study-abroad/new-zealand",
    universities: [
      {
        name: "University of Auckland",
        rank: "68th worldwide",
        location: "Auckland, New Zealand",
        link: "https://www.auckland.ac.nz/",
      },
      {
        name: "University of Otago",
        rank: "184th worldwide",
        location: "Dunedin, New Zealand",
        link: "https://www.otago.ac.nz/",
      },
      {
        name: "University of Canterbury",
        rank: "251-300th worldwide",
        location: "Christchurch, New Zealand",
        link: "https://www.canterbury.ac.nz/",
      },
      {
        name: "Victoria University of Wellington",
        rank: "301-350th worldwide",
        location: "Wellington, New Zealand",
        link: "https://www.victoria.ac.nz/",
      },
      {
        name: "Auckland University of Technology (AUT)",
        rank: "401-500th worldwide",
        location: "Auckland, New Zealand",
        link: "https://www.aut.ac.nz/",
      },
      {
        name: "Massey University",
        rank: "401-500th worldwide",
        location: "Palmerston North, New Zealand",
        link: "https://www.massey.ac.nz/",
      },
      {
        name: "University of Waikato",
        rank: "501-600th worldwide",
        location: "Hamilton, New Zealand",
        link: "https://www.waikato.ac.nz/",
      },
      {
        name: "Lincoln University",
        rank: "501-600th worldwide",
        location: "Lincoln, New Zealand",
        link: "https://www.lincoln.ac.nz/",
      },
      {
        name: "Unitec Institute of Technology",
        rank: "801-1000th worldwide",
        location: "Auckland, New Zealand",
        link: "https://www.unitec.ac.nz/",
      },
      {
        name: "Waikato Institute of Technology (Wintec)",
        rank: "801-1000th worldwide",
        location: "Hamilton, New Zealand",
        link: "https://www.wintec.ac.nz/",
      },
      {
        name: "Southern Institute of Technology (SIT)",
        rank: "801-1000th worldwide",
        location: "Invercargill, New Zealand",
        link: "https://www.sit.ac.nz/",
      },
      {
        name: "Otago Polytechnic",
        rank: "801-1000th worldwide",
        location: "Dunedin, New Zealand",
        link: "https://www.op.ac.nz/",
      },
      {
        name: "Aoraki Polytechnic",
        rank: "801-1000th worldwide",
        location: "Timaru, New Zealand",
        link: "https://www.aoraki.ac.nz/",
      },
      {
        name: "Tai Poutini Polytechnic",
        rank: "801-1000th worldwide",
        location: "Greymouth, New Zealand",
        link: "https://www.tpp.ac.nz/",
      },
      {
        name: "Manukau Institute of Technology (MIT)",
        rank: "801-1000th worldwide",
        location: "Auckland, New Zealand",
        link: "https://www.manukau.ac.nz/",
      },
      {
        name: "EIT - Eastern Institute of Technology",
        rank: "801-1000th worldwide",
        location: "Hawke's Bay, New Zealand",
        link: "https://www.eit.ac.nz/",
      },
      {
        name: "Wellington Institute of Technology (WelTec)",
        rank: "801-1000th worldwide",
        location: "Wellington, New Zealand",
        link: "https://www.weltec.ac.nz/",
      },
      {
        name: "NorthTec - Northland Polytechnic",
        rank: "801-1000th worldwide",
        location: "Whangarei, New Zealand",
        link: "https://www.northtec.ac.nz/",
      },
      {
        name: "Southern Institute of Technology (SIT)",
        rank: "801-1000th worldwide",
        location: "Invercargill, New Zealand",
        link: "https://www.sit.ac.nz/",
      },
      {
        name: "Auckland University of Technology (AUT)",
        rank: "401-500th worldwide",
        location: "Auckland, New Zealand",
        link: "https://www.aut.ac.nz/",
      },
      {
        name: "Massey University",
        rank: "401-500th worldwide",
        location: "Palmerston North, New Zealand",
        link: "https://www.massey.ac.nz/",
      },
      {
        name: "University of Waikato",
        rank: "501-600th worldwide",
        location: "Hamilton, New Zealand",
        link: "https://www.waikato.ac.nz/",
      },
      {
        name: "Lincoln University",
        rank: "501-600th worldwide",
        location: "Lincoln, New Zealand",
        link: "https://www.lincoln.ac.nz/",
      },
      {
        name: "Unitec Institute of Technology",
        rank: "801-1000th worldwide",
        location: "Auckland, New Zealand",
        link: "https://www.unitec.ac.nz/",
      },
      {
        name: "Waikato Institute of Technology (Wintec)",
        rank: "801-1000th worldwide",
        location: "Hamilton, New Zealand",
        link: "https://www.wintec.ac.nz/",
      },
      {
        name: "Southern Institute of Technology (SIT)",
        rank: "801-1000th worldwide",
        location: "Invercargill, New Zealand",
        link: "https://www.sit.ac.nz/",
      },
      {
        name: "Otago Polytechnic",
        rank: "801-1000th worldwide",
        location: "Dunedin, New Zealand",
        link: "https://www.op.ac.nz/",
      },
      {
        name: "Aoraki Polytechnic",
        rank: "801-1000th worldwide",
        location: "Timaru, New Zealand",
        link: "https://www.aoraki.ac.nz/",
      },
      {
        name: "Tai Poutini Polytechnic",
        rank: "801-1000th worldwide",
        location: "Greymouth, New Zealand",
        link: "https://www.tpp.ac.nz/",
      },
      {
        name: "Manukau Institute of Technology (MIT)",
        rank: "801-1000th worldwide",
        location: "Auckland, New Zealand",
        link: "https://www.manukau.ac.nz/",
      },
      {
        name: "EIT - Eastern Institute of Technology",
        rank: "801-1000th worldwide",
        location: "Hawke's Bay, New Zealand",
        link: "https://www.eit.ac.nz/",
      },
      {
        name: "Wellington Institute of Technology (WelTec)",
        rank: "801-1000th worldwide",
        location: "Wellington, New Zealand",
        link: "https://www.weltec.ac.nz/",
      },
      {
        name: "NorthTec - Northland Polytechnic",
        rank: "801-1000th worldwide",
        location: "Whangarei, New Zealand",
        link: "https://www.northtec.ac.nz/",
      },
    ],
  },
  {
    id: 6,
    name: "Europe",
    image: "europe.png",
    description:
      "For centuries, education in Europe has been seen as a traditional experience that leads to modern career opportunities.",
    link: "/study-abroad/europe",
    universities: [
      {
        name: "ETH Zurich",
        rank: "6th worldwide",
        location: "Zurich, Switzerland",
        link: "https://ethz.ch/",
      },
      {
        name: "University of Amsterdam",
        rank: "58th worldwide",
        location: "Amsterdam, Netherlands",
        link: "https://www.uva.nl/",
      },
      {
        name: "University of Copenhagen",
        rank: "85th worldwide",
        location: "Copenhagen, Denmark",
        link: "https://www.ku.dk/english/",
      },
      {
        name: "Sorbonne University",
        rank: "35th worldwide",
        location: "Paris, France",
        link: "https://www.sorbonne-universite.fr/en",
      },
      {
        name: "Ludwig Maximilian University of Munich (LMU)",
        rank: "59th worldwide",
        location: "Munich, Germany",
        link: "https://www.en.uni-muenchen.de/index.html",
      },
      {
        name: "Humboldt University of Berlin",
        rank: "128th worldwide",
        location: "Berlin, Germany",
        link: "https://www.hu-berlin.de/en?set_language=en",
      },
      {
        name: "Heidelberg University",
        rank: "66th worldwide",
        location: "Heidelberg, Germany",
        link: "https://www.uni-heidelberg.de/",
      },
      {
        name: "University of Helsinki",
        rank: "96th worldwide",
        location: "Helsinki, Finland",
        link: "https://www.helsinki.fi/en",
      },
      {
        name: "KU Leuven",
        rank: "76th worldwide",
        location: "Leuven, Belgium",
        link: "https://www.kuleuven.be/english/",
      },
      {
        name: "University of Vienna",
        rank: "154th worldwide",
        location: "Vienna, Austria",
        link: "https://www.univie.ac.at/en/",
      },
      {
        name: "Delft University of Technology",
        rank: "57th worldwide",
        location: "Delft, Netherlands",
        link: "https://www.tudelft.nl/en/",
      },
      {
        name: "Trinity College Dublin",
        rank: "98th worldwide",
        location: "Dublin, Ireland",
        link: "https://www.tcd.ie/",
      },
      {
        name: "Ecole Polytechnique Fédérale de Lausanne (EPFL)",
        rank: "19th worldwide",
        location: "Lausanne, Switzerland",
        link: "https://www.epfl.ch/en/",
      },
      {
        name: "University of Oslo",
        rank: "113th worldwide",
        location: "Oslo, Norway",
        link: "https://www.uio.no/english/",
      },
      {
        name: "Sciences Po",
        rank: "242nd worldwide",
        location: "Paris, France",
        link: "https://www.sciencespo.fr/en",
      },
      {
        name: "University of Zurich",
        rank: "73rd worldwide",
        location: "Zurich, Switzerland",
        link: "https://www.uzh.ch/en.html",
      },
      {
        name: "University of Barcelona",
        rank: "166th worldwide",
        location: "Barcelona, Spain",
        link: "https://www.ub.edu/web/ub/en/",
      },
      {
        name: "Uppsala University",
        rank: "116th worldwide",
        location: "Uppsala, Sweden",
        link: "https://www.uu.se/en",
      },
      {
        name: "Leiden University",
        rank: "120th worldwide",
        location: "Leiden, Netherlands",
        link: "https://www.universiteitleiden.nl/en",
      },
      {
        name: "University of Milan",
        rank: "183rd worldwide",
        location: "Milan, Italy",
        link: "https://www.unimi.it/en",
      },
      {
        name: "University of Warsaw",
        rank: "308th worldwide",
        location: "Warsaw, Poland",
        link: "https://www.uw.edu.pl/en/",
      },
      {
        name: "University of Bologna",
        rank: "160th worldwide",
        location: "Bologna, Italy",
        link: "https://www.unibo.it/en",
      },
      {
        name: "Utrecht University",
        rank: "81st worldwide",
        location: "Utrecht, Netherlands",
        link: "https://www.uu.nl/en",
      },
      {
        name: "Lund University",
        rank: "103rd worldwide",
        location: "Lund, Sweden",
        link: "https://www.lu.se/lund-university",
      },
      {
        name: "University of Geneva",
        rank: "128th worldwide",
        location: "Geneva, Switzerland",
        link: "https://www.unige.ch/en/",
      },
      {
        name: "University of Edinburgh",
        rank: "22nd worldwide",
        location: "Edinburgh, Scotland",
        link: "https://www.ed.ac.uk/",
      },
      {
        name: "University of Gothenburg",
        rank: "145th worldwide",
        location: "Gothenburg, Sweden",
        link: "https://www.gu.se/en",
      },
      {
        name: "University of Porto",
        rank: "201-250th worldwide",
        location: "Porto, Portugal",
        link: "https://www.up.pt/en",
      },
      {
        name: "University of Stuttgart",
        rank: "201-250th worldwide",
        location: "Stuttgart, Germany",
        link: "https://www.uni-stuttgart.de/en/",
      },
      {
        name: "University of Erlangen-Nuremberg",
        rank: "251-300th worldwide",
        location: "Nuremberg, Germany",
        link: "https://www.fau.de/en/",
      },
      {
        name: "University of Vienna",
        rank: "154th worldwide",
        location: "Vienna, Austria",
        link: "https://www.univie.ac.at/en/",
      },
      {
        name: "University of Twente",
        rank: "201-250th worldwide",
        location: "Enschede, Netherlands",
        link: "https://www.utwente.nl/en/",
      },
      {
        name: "Università degli Studi di Napoli Federico II",
        rank: "301-350th worldwide",
        location: "Naples, Italy",
        link: "https://www.unina.it/en",
      },
      {
        name: "Università degli Studi di Milano-Bicocca",
        rank: "301-350th worldwide",
        location: "Milan, Italy",
        link: "https://www.unimib.it/en",
      },
      {
        name: "University of Padua",
        rank: "301-350th worldwide",
        location: "Padua, Italy",
        link: "https://www.unipd.it/en",
      },
      {
        name: "University of Rome La Sapienza",
        rank: "351-400th worldwide",
        location: "Rome, Italy",
        link: "https://www.uniroma1.it/en",
      },
    ],
    Countries: [
      {
        name: "Albania",
        flag: "https://flagcdn.com/al.svg",
        govPortal: "https://kryeministria.al",
      },
      {
        name: "Andorra",
        flag: "https://flagcdn.com/ad.svg",
        govPortal: "https://www.govern.ad",
      },
      {
        name: "Armenia",
        flag: "https://flagcdn.com/am.svg",
        govPortal: "https://www.gov.am",
      },
      {
        name: "Austria",
        flag: "https://flagcdn.com/at.svg",
        govPortal: "https://www.oesterreich.gv.at",
      },
      {
        name: "Azerbaijan",
        flag: "https://flagcdn.com/az.svg",
        govPortal: "https://www.gov.az",
      },
      {
        name: "Belarus",
        flag: "https://flagcdn.com/by.svg",
        govPortal: "http://president.gov.by",
      },
      {
        name: "Belgium",
        flag: "https://flagcdn.com/be.svg",
        govPortal: "https://www.belgium.be",
      },
      {
        name: "Bosnia and Herzegovina",
        flag: "https://flagcdn.com/ba.svg",
        govPortal: "http://fbihvlada.gov.ba",
      },
      {
        name: "Bulgaria",
        flag: "https://flagcdn.com/bg.svg",
        govPortal: "https://www.gov.bg",
      },
      {
        name: "Croatia",
        flag: "https://flagcdn.com/hr.svg",
        govPortal: "https://www.vlada.hr",
      },
      {
        name: "Cyprus",
        flag: "https://flagcdn.com/cy.svg",
        govPortal: "http://www.cyprus.gov.cy",
      },
      {
        name: "Czech Republic",
        flag: "https://flagcdn.com/cz.svg",
        govPortal: "https://www.vlada.cz",
      },
      {
        name: "Denmark",
        flag: "https://flagcdn.com/dk.svg",
        govPortal: "https://www.denmark.dk",
      },
      {
        name: "Estonia",
        flag: "https://flagcdn.com/ee.svg",
        govPortal: "https://www.valitsus.ee",
      },
      {
        name: "Finland",
        flag: "https://flagcdn.com/fi.svg",
        govPortal: "https://www.suomi.fi",
      },
      {
        name: "France",
        flag: "https://flagcdn.com/fr.svg",
        govPortal: "https://www.gouvernement.fr",
      },
      {
        name: "Georgia",
        flag: "https://flagcdn.com/ge.svg",
        govPortal: "https://www.gov.ge",
      },
      {
        name: "Germany",
        flag: "https://flagcdn.com/de.svg",
        govPortal: "https://www.bundesregierung.de",
      },
      {
        name: "Greece",
        flag: "https://flagcdn.com/gr.svg",
        govPortal: "https://www.gov.gr",
      },
      {
        name: "Hungary",
        flag: "https://flagcdn.com/hu.svg",
        govPortal: "https://www.kormany.hu",
      },
      {
        name: "Iceland",
        flag: "https://flagcdn.com/is.svg",
        govPortal: "https://www.government.is",
      },
      {
        name: "Ireland",
        flag: "https://flagcdn.com/ie.svg",
        govPortal: "https://www.gov.ie",
      },
      {
        name: "Italy",
        flag: "https://flagcdn.com/it.svg",
        govPortal: "https://www.governo.it",
      },
      {
        name: "Kazakhstan",
        flag: "https://flagcdn.com/kz.svg",
        govPortal: "https://www.government.kz",
      },
      {
        name: "Kosovo",
        flag: "https://flagcdn.com/xk.svg",
        govPortal: "https://kryeministri.rks-gov.net",
      },
      {
        name: "Latvia",
        flag: "https://flagcdn.com/lv.svg",
        govPortal: "https://www.mk.gov.lv",
      },
      {
        name: "Liechtenstein",
        flag: "https://flagcdn.com/li.svg",
        govPortal: "https://www.llv.li",
      },
      {
        name: "Lithuania",
        flag: "https://flagcdn.com/lt.svg",
        govPortal: "https://lrv.lt",
      },
      {
        name: "Luxembourg",
        flag: "https://flagcdn.com/lu.svg",
        govPortal: "https://gouvernement.lu",
      },
      {
        name: "Malta",
        flag: "https://flagcdn.com/mt.svg",
        govPortal: "https://gov.mt",
      },
      {
        name: "Moldova",
        flag: "https://flagcdn.com/md.svg",
        govPortal: "https://gov.md",
      },
      {
        name: "Monaco",
        flag: "https://flagcdn.com/mc.svg",
        govPortal: "https://www.gouv.mc",
      },
      {
        name: "Montenegro",
        flag: "https://flagcdn.com/me.svg",
        govPortal: "https://www.gov.me",
      },
      {
        name: "Netherlands",
        flag: "https://flagcdn.com/nl.svg",
        govPortal: "https://www.government.nl",
      },
      {
        name: "North Macedonia",
        flag: "https://flagcdn.com/mk.svg",
        govPortal: "https://vlada.mk",
      },
      {
        name: "Norway",
        flag: "https://flagcdn.com/no.svg",
        govPortal: "https://www.regjeringen.no",
      },
      {
        name: "Poland",
        flag: "https://flagcdn.com/pl.svg",
        govPortal: "https://www.gov.pl",
      },
      {
        name: "Portugal",
        flag: "https://flagcdn.com/pt.svg",
        govPortal: "https://www.portugal.gov.pt",
      },
      {
        name: "Romania",
        flag: "https://flagcdn.com/ro.svg",
        govPortal: "https://www.gov.ro",
      },
      {
        name: "Russia",
        flag: "https://flagcdn.com/ru.svg",
        govPortal: "http://government.ru",
      },
      {
        name: "San Marino",
        flag: "https://flagcdn.com/sm.svg",
        govPortal: "http://www.sanmarino.sm",
      },
      {
        name: "Serbia",
        flag: "https://flagcdn.com/rs.svg",
        govPortal: "https://www.srbija.gov.rs",
      },
      {
        name: "Slovakia",
        flag: "https://flagcdn.com/sk.svg",
        govPortal: "https://www.vlada.gov.sk",
      },
      {
        name: "Slovenia",
        flag: "https://flagcdn.com/si.svg",
        govPortal: "https://www.gov.si",
      },
      {
        name: "Spain",
        flag: "https://flagcdn.com/es.svg",
        govPortal: "https://www.lamoncloa.gob.es",
      },
      {
        name: "Sweden",
        flag: "https://flagcdn.com/se.svg",
        govPortal: "https://www.government.se",
      },
      {
        name: "Switzerland",
        flag: "https://flagcdn.com/ch.svg",
        govPortal: "https://www.admin.ch",
      },
      {
        name: "Turkey",
        flag: "https://flagcdn.com/tr.svg",
        govPortal: "https://www.turkiye.gov.tr",
      },
      {
        name: "Ukraine",
        flag: "https://flagcdn.com/ua.svg",
        govPortal: "https://www.kmu.gov.ua",
      },
      {
        name: "United Kingdom",
        flag: "https://flagcdn.com/gb.svg",
        govPortal: "https://www.gov.uk",
      },
      {
        name: "Vatican City",
        flag: "https://flagcdn.com/va.svg",
        govPortal: "http://www.vatican.va",
      },
    ],
  },
];

export default Destinations;
