import React from "react";
import destinations from "../data/destinationdata"; // adjust the path as needed

function Destination() {
  return (
    <div className="px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Study Abroad</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 items-start justify-center">
        {destinations.map((destination, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-start w-full h-[450px] bg-white rounded-lg shadow-md p-6 transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-gray-50 duration-300 ease-in-out"
          >
            <img
              src={destination.image}
              alt={`${destination.name}`}
              className="w-full h-48 object-cover rounded-t-lg mb-4"
            />
            <h2 className="text-xl font-semibold mb-2">{destination.name}</h2>
            <p className="text-gray-700 mb-4 text-center flex-grow">
              {destination.description}
            </p>
            <a
              href={destination.link}
              className="bg-[#eb4934] px-4 py-2 text-white font-bold rounded hover:bg-[#d43b2e] no-underline mt-auto"
              style={{ marginBottom: "10px" }}
            >
              Know More
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Destination;
